import FiveGridLine_SolidLineStudioComponent from '@wix/thunderbolt-elements/src/components/FiveGridLine/viewer/skinComps/SolidLineStudio/SolidLineStudio.skin';


const FiveGridLine_SolidLineStudio = {
  component: FiveGridLine_SolidLineStudioComponent
};


export const components = {
  ['FiveGridLine_SolidLineStudio']: FiveGridLine_SolidLineStudio
};

